<template>
  <v-container fluid>
    <v-card elevation="6">
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
        <span>{{ '' || this.goodReceipt && this.goodReceipt.goodsIssueName }}</span>
        <v-spacer/>


        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="loadingCommit || makeChange"
                :loading="loadingCommit"
                @click="commitItems"
                color="primary"
                icon
                v-if="Protected==0" v-on="on">
              <v-icon>check</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("erp.lang_commit_lock") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="loadingSave"
                :loading="loadingSave"
                @click="save"
                color="success"
                icon
                v-if="Protected==0" v-on="on">
              <v-icon>save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('generic.lang_save') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                @click="clear"
                color="error"
                icon
                v-on="on">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('generic.lang_delete') }}</span>
        </v-tooltip>


      </v-card-title>
      <v-divider class="pa-0 ma-0" v-if="Protected==0"/>
      <v-card-text class="pa-0 ma-0">
        <v-form lazy-validation onsubmit="return false" ref="form">
          <v-row align="center" justify="center">
            <v-col class="mx-auto" cols="12" md="6" sm="8" v-if="Protected==0">
              <v-card-text>
                <v-menu
                    max-height="300px"
                    class="white"
                    v-model="items_menu"
                    open-on-focus
                    offset-y
                    close-on-content-click
                    :close-on-click="false"
                >
                  <template v-slot:activator="{}">
                    <v-text-field
                        autofocus
                        :placeholder="$t('datatables.lang_search')"
                        prepend-inner-icon="mdi-database-search"
                        hide-details
                        outlined
                        ref="autocomplete"
                        dense
                        v-model="search"
                        :loading="isLoading"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @focus="showTouchKeyboard"
                        @keyup="items_menu = true"
                        @click="items_menu = true"
                    >
                    </v-text-field>
                  </template>

                  <v-list v-if="Array.isArray(entries) && entries.length > 0">
                    <v-list-item
                        @click="scan(item)"
                        :key="item.id"
                        v-for="item in entries"
                    >
                      <v-list-item-content>
                        {{ item.name }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div v-else class="text-center w-100">{{ $t("generic.lang_noItemsFound") }}</div>
                </v-menu>

                <!--
                                <v-autocomplete
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :filter="Filter"
                                    :items="this.entries"
                                    :loading="isLoading"
                                    :placeholder="this.$t('datatables.lang_search')"
                                    :search-input.sync="search"
                                    @change="scan"
                                    @focus="showTouchKeyboard"
                                    autofocus
                                    hide-selected
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    prepend-inner-icon="mdi-database-search"
                                    ref="autocomplete"
                                    return-object
                                    v-model="current"
                                >
                                  <template v-slot:item="{item}">
                                                    <span v-if="(item.color.length > 0 && item.size.length > 0)">{{ item.name }}
                                                      ({{ 'color:' + item.color + ' , ' + 'size:' + item.size }})
                                                    </span>
                                    <span v-else-if="(item.color.length > 0 && item.size.length == 0)">{{ item.name }}
                                                      ({{ 'color:' + item.color }})
                                                    </span>
                                    <span v-else-if="(item.color.length == 0 && item.size.length > 0)">{{ item.name }}
                                                      ({{ 'size:' + item.size }})
                                                    </span>
                                    <span v-else>
                                                      {{ item.name }}
                                                    </span>
                                  </template>
                                </v-autocomplete>-->
              </v-card-text>
            </v-col>
            <v-col cols="12" v-if="this.selectedItems && this.selectedItems.length > 0">
              <v-divider class="ma-0 pa-0"/>
            </v-col>
            <v-col class="mt-0 pt-0" cols="12">
              <v-data-table
                  :headers="headers"
                  :items="this.selectedItems"
                  :items-per-page="15"
                  class="elevation-0"
              >
                <template v-slot:item.buy_price="{item}">
                  {{ item.buy_price | currency }}
                </template>

                <template v-slot:item.ean="{item}">
                  <strong class="primary--text">{{ item.ean }}</strong>
                </template>

                <template v-slot:item.qty="{ item }" v-if="Protected==0">
                  <v-text-field
                    v-model="item.qty"
                    :rules="[(v) => !!v]"
                    class="mt-7"
                    dense
                    outlined
                    type="number"
                    :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                    @focus="showTouchKeyboard"
                  />
                </template>

                <template v-slot:item.bestBeforeDate="{item}">
                  <strong v-if="item.bestBeforeDate !== 0">{{ item.bestBeforeDate }}</strong>
                </template>

                <template v-slot:item.color="{item}">
                  <v-chip :color="item.color" class="colors"
                          v-if="item.color !== undefined && item.color !== ''"></v-chip>
                </template>

                <template v-slot:item.actions="{item}">

                  <!-- <v-btn @click="editItem(item)" color="primary" icon v-if="Protected==0">
                    <v-icon>edit</v-icon>
                  </v-btn> -->
                  <v-btn @click="deleteItem(item)" color="error" icon v-if="Protected==0">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>

              </v-data-table>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>


      <v-card-text v-if="edit && editedItem">
        <v-row justify="center">
          <v-dialog max-width="500" persistent v-model="edit">
            <!--editting dialog-->
            <v-card class="pa-3">
              <v-card-text class="">
                <v-form lazy-validation onsubmit="return false" ref="edit">
                  <v-row>


                    <v-col class="" cols="12">
                      <v-text-field :label="this.$t('erp.lang_quantity')" :rules="[v => !!v]"
                                    @keypress.enter="saveItem"
                                    dense
                                    :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                    @focus="showTouchKeyboard"
                                    outlined type="number"
                                    v-model="editedItem.qty"/>
                    </v-col>


                    <v-col class="text-right pa-0 ma-0" cols="12">
                      <v-btn @click="close" color="error" text>
                        {{ this.$t('generic.lang_cancel') }}
                      </v-btn>
                      <v-btn @click="saveItem" color="primary" text>
                        {{ this.$t('generic.lang_save') }}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                    :defaultKeySet="touchKeyboard.keySet"
                                    :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                    :options="touchKeyboard.options" class="internalWidthExpanded"
                                    id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
              </div>
  </v-container>
</template>

<script>

//configs
import mixin from "../../../../mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";
import Birthdaypicker from "../../../common/birthdaypicker";
import Template from "../../../settings/customerdisplay/Template";

export default {
  name: "GoodsReceiptEdit",

  components: {Template, Birthdaypicker},

  mixins: [mixin],

  data: () => {
    return {
      items_menu: false,
      ENDPOINT: ENDPOINTS,
      goodReceipt: null,
      awaitingSearch: null,
      hasChargenNo: false,
      hasMHD: false,
      chargeDue: false,
      entries: [],
      selectedItems: [/*{ean , name, qty, bestBeforeDate , batchNo, serialNo, color,size}*/],
      Protected: false,
      isLoading: false,
      selected: null,
      search: null,
      loadingSelect: false,
      current: null,
      editedItem: null,
      editedIndex: -1,
      edit: false,
      loadingSave: false,
      loadingCommit: false,
      loadingClear: false,
      makeChange: false,
      items: []
    }
  },
  methods: {
    close() {
      this.hasChargenNo = this.hasMHD = this.chargeDue = this.edit = false
      this.selected = this.editedItem = null;
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.selectedItems.indexOf(item);
      this.edit = true;
    },
    clear() {

      let self = this;
      this.$swal({
        title: this.$t("generic.lang_doYouReallyWantToClear") + " " + self.goodReceipt.goodsReceiptName + "?",
        text: this.$t("generic.lang_doYouReallyWantToClear") + " " + self.goodReceipt.goodsReceiptName + "?",
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.deleteData([self.$route.params.id]);
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    deleteItem(item) {
      let self = this;
      this.$swal({
        title: this.$t("generic.lang_doYouReallyWantToDelete") + " " + item.name + "?",
        text: this.$t("generic.lang_doYouReallyWantToDelete") + " " + item.name + "?",
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const index = self.selectedItems.indexOf(self.getItemByIDandEAN(item.ean));
          this.selectedItems.splice(index, 1)
          this.makeChange = true;
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    saveChargeDue() {
      if (!this.$refs.chargeDue.validate()) return;
      this.push(this.selected)
      this.chargeDue = false;
      this.selected = null;
    },
    saveItem() {
      if (!this.$refs.edit.validate()) return;

      Object.assign(this.selectedItems[this.editedIndex], this.editedItem);

      this.editedIndex = -1;
      this.editedItem = null;
      this.edit = false;
    },
    setHumainDate() {
      if (this.selectedItems) {
        this.selectedItems.forEach((item) => {
          if (item.bestBeforeDate == 0)
            item.bestBeforeDate = ""
          else
            item.bestBeforeDate = new Date(item.bestBeforeDate * 1000).getFullYear() + "-" + (new Date(item.bestBeforeDate * 1000).getMonth() + 1) + "-" + new Date(item.bestBeforeDate * 1000).getDate()
        })
      }
    },
    setUnixTimestamp() {
      let tmp = [];
      if (this.selectedItems) {
        this.selectedItems.forEach((item) => {
          let tmpItem = Object.assign({}, item);
          tmpItem.bestBeforeDate = Math.round(+new Date(item.bestBeforeDate).getTime() / 1000);
          tmp.push(tmpItem)
        })
      }
      return tmp;
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.loadingClear = false;
      this.axios.post(ENDPOINTS.ERP.GOODSRECEIPT.CLEAR, {
        storageTransferIDs: idsToDelete
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemSuccessfulDeleted'),
            color: "success"
          });

          self.selectedItems = [];
          self.$router.push('/erp/storage/GoodsReceipt')

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        }).finally(() => {
          self.loadingClear = false;
        });
      });
    },
    commitItems() {
      this.loadingCommit = true;
      this.axios.post(ENDPOINTS.ERP.GOODSRECEIPT.COMMIT, {
        storageTransferID: this.$route.params.id
      }).then((res) => {

        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemsSuccessfullImported'),
            color: "success"
          });
          this.$router.push('/erp/storage/GoodsIssue')
        } else if (res.data.status === 'ERROR') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCommit = false;
      })

    },
    save() //save items
    {
      let form = {};
      this.loadingSave = true;
      form.storageTransferID = this.$route.params.id;
      form.items = this.setUnixTimestamp();


      this.axios.post(ENDPOINTS.ERP.GOODSRECEIPT.SAVEITEMS, form).then((res) => {
        //console.log(res);

        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemsSuccessfullImported'),
            color: "success"
          });
          this.makeChange = false;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSave = false;
      })
    },
    push(item) {
      if (item) {
        this.makeChange = true;
        this.selectedItems.push({
          id: item.id,
          ean: item.ean,
          name: item.name,
          qty: 1,
          buy_price: item.buy_price,
          serialNo: item.serialNo,
          color: item.color,
          size: item.size
        })
      }
    },
    scan(pickedItem) {
      this.current = {...pickedItem};
      this.selected = Object.assign({}, this.current);

      this.$nextTick(() => {
        if (this.isExists(this.selected.ean)) { //check if exist then increase qty
          const index = this.selectedItems.indexOf(this.getItemByIDandEAN(this.selected.ean)),
              item = this.getItemByIDandEAN(this.selected.ean);
          item.qty++;
          this.makeChange = true;
          Object.assign(this.selectedItems[index], item)
        } else {
          this.push(this.selected);
        }

        this.$refs.autocomplete.reset();
        this.$nextTick(() => {
          this.$refs.autocomplete.focus();
          this.showTouchKeyboardWithoutEvent();
        })
      })
    },
    isExists(ean) {
      const result = this.selectedItems.filter((item) => (item.ean === ean))
      //console.log(result)
      return (result.length > 0)
    },
    getItemByIDandEAN(ean) {
      const result = this.selectedItems.filter((item) => (item.ean === ean))
      if (result.length > 0)
        return result[0];
      return null;
    },
    Filter(item, queryText, itemText) {
      //return all item whose names starts with "queryText"
      return (itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) || item.ean.toLowerCase().includes(queryText.toLowerCase()) || item.name.toLowerCase().includes(queryText.toLowerCase());
    },
    async loadGoodIssueDetails() {
      await this.axios.post(this.ENDPOINT.ERP.GOODSISSUE.SHOW,
          {
            goodsIssueUUID: this.$route.params.id,
          }).then((res) => {
        if (res.data.formfillData) {
          this.goodReceipt = res.data.formfillData.textFields;
          this.selectedItems = res.data.items;
          this.Protected = res.data.protected;
          this.setHumainDate();
        }


      }).catch((err) => {
        this.loadingSelect = false;
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      })
    }
  },
  async mounted() {
    this.items = await this.$store.dispatch("items/getItems");

    this.loadGoodIssueDetails();
  },
  computed: {
    currentDate: function () {
      let dt = new Date();
      return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate()
    },
    headers: function () {
      return [
        {text: this.$t('erp.lang_ware_create_id'), value: "ean"},
        {text: this.$t('erp.lang_itemname'), value: "name", sortable: false},
        {text: this.$t('erp.lang_quantity'), value: "qty", align: 'center'},
        {text: this.$t('erp.lang_PurchaseValue'), value: "buy_price", align: 'center'},
        {text: this.$t('erp.lang_bestBeforeDate'), value: "bestBeforeDate"},
        {text: this.$t('erp.lang_chargeNo'), value: "batchNo", sortable: false},
        {text: this.$t('erp.lang_serialnumber'), value: "serialNo"},
        {text: this.$t('erp.lang_warecreate_color'), value: "color", sortable: false},
        {text: this.$t('generic.lang_size'), value: "size", sortable: false},
        {text: "", value: "actions"},
      ]
    }
  }
  ,
  watch: {
    items_menu(v) {
      if (v) this.entries = [];
    },
    search(v) {
      clearTimeout(this.awaitingSearch);
      this.items_menu = true;
      this.awaitingSearch = setTimeout(() => {
        if ((v && v.length == 0) || !!v == false) {
          return;
        }


        // Items have already been requested
        //if (this.isLoading) return;

        //clear old data
        /*if ((this.current && this.current.name !== v) || (this.current && this.current.name === this.search)) {
          this.$refs.autocomplete.reset();
          return;
        }*/

        this.isLoading = true
        this.axios.post(ENDPOINTS.ERP.ITEM.SEARCH, {
          query: v
        }).then((res) => {
          if (res.data.status === "SUCCESS") {
            this.entries = res.data.data.foundItems;
          }
        }).catch(() => {

        }).finally(() => {
          this.isLoading = false;
        })
      }, 500);
    },
  }
  ,
}
</script>

<style>
.colors {
  width: 10px !important;
  height: 25px !important;
}
</style>
